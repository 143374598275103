import { useOnMount } from "@uxf/core/hooks/useOnMount";
import { RefObject } from "react";

export const useHandleRealHeight = (
    ref: RefObject<HTMLElement>,
    heightType: Extract<keyof CSSStyleDeclaration, "height" | "minHeight"> = "height",
    handleBodyOverflow = false,
) => {
    return useOnMount(() => {
        if (handleBodyOverflow) {
            window.document.body.style.overflow = "hidden";
        }

        const handleMenuHeight = () => {
            const node = ref.current;
            if (node) {
                node.style[heightType] = `${window.visualViewport?.height ?? window.innerHeight}px`;
            }
        };

        handleMenuHeight();
        window.addEventListener("resize", handleMenuHeight);
        return () => {
            if (handleBodyOverflow) {
                window.document.body.style.overflow = "unset";
            }
            window.removeEventListener("resize", handleMenuHeight);
        };
    });
};
