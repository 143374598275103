import { routeToUrl } from "@app-routes";
import { IconName } from "@uxf/ui/icon/types";

export type MenuItemType = { icon: IconName; label: string; href: string; enforceBrowserReload?: boolean };

export const USER_MENU_CONFIG: MenuItemType[] = [
    {
        icon: "clock-rotate-left",
        label: "Historie transakcí",
        href: routeToUrl("user-zone/credit-history"),
    },
    {
        icon: "plate-utensils",
        label: "Přehled mých Předplátek",
        href: routeToUrl("user-zone/my-meals-history"),
    },
    {
        icon: "receipt",
        label: "Moje účtenky",
        href: routeToUrl("user-zone/my-receipts"),
    },
    {
        icon: "lock-keyhole",
        label: "Změnit heslo",
        href: routeToUrl("user-zone/change-password"),
    },
    {
        icon: "arrow-right-to-bracket",
        label: "Odhlásit se",
        href: routeToUrl("user-zone/logout"),
        enforceBrowserReload: true,
    },
];

export const APP_MENU_CONFIG: MenuItemType[] = [
    {
        icon: "clock-rotate-left",
        label: "Novinky na Předplátku",
        href: "#",
    },
    {
        icon: "clock-rotate-left",
        label: "Blog",
        href: "#",
    },
];
