import { routeToUrl } from "@app-routes";
// eslint-disable-next-line import/no-restricted-paths
import { formattedTomorrow } from "@main-zone/constants";
import Logo from "@public/images/logo.svg";
import { useLastLocation } from "@shared/lib/last-location/last-location";
import { useCurrentUser } from "@shared/utils/use-current-user";
import { cx } from "@uxf/core/utils/cx";
import { Button } from "@uxf/ui/button";
import { Icon } from "@uxf/ui/icon";
import { RasterImage } from "@uxf/ui/raster-image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, ReactElement } from "react";
import { Navigation } from "./navigation";

type HeaderProps = {
    title?: ReactElement | string;
    hideLogoBarOnMobile?: boolean;
    hideTitleBarOnDesktop?: boolean;
    className?: string;
    rightElement?: ReactElement;
    hideBackButtonOnDesktop?: boolean;
} & (
    | {
          backButton?: string | true;
          closeButton?: never;
      }
    | {
          closeButton?: string | true;
          backButton?: never;
      }
);

export const Header: FC<HeaderProps> = (props) => {
    const user = useCurrentUser();
    const { back } = useRouter();
    const lastLocation = useLastLocation();

    const backValue = props.backButton || props.closeButton;
    const goBackUrl = backValue === true ? lastLocation : backValue;

    return (
        <>
            <header
                className={cx(
                    "sticky z-fixed border-b bg-white px-4 md:px-10",
                    props.title && "top-0 md:top-[-72px]",
                    props.className,
                )}
            >
                <div
                    className={`h-[72px] items-center justify-between py-2 ${
                        props.hideLogoBarOnMobile ? "hidden md:flex" : "flex"
                    }`}
                >
                    <Link
                        href={routeToUrl("main-zone/index")}
                        className="text-xl font-semibold text-lightHigh md:hidden"
                    >
                        <RasterImage src={Logo} alt="Předplátko.cz" className="h-12" width={135} height={48} />
                    </Link>
                    <Link
                        href={routeToUrl("main-zone/restaurant-search", {
                            date: formattedTomorrow,
                        })}
                        className="hidden text-desktopH4 font-semibold text-lightHigh md:inline"
                    >
                        <RasterImage src={Logo} alt="Předplátko.cz" className="h-12" width={135} height={48} />
                    </Link>
                    {user?.isLogged ? (
                        <Navigation />
                    ) : (
                        <Link href={routeToUrl("user-zone/login", {})} legacyBehavior passHref>
                            <Button variant="text" size="sm" className="border">
                                Přihlásit se
                            </Button>
                        </Link>
                    )}
                </div>
                {props.title && (
                    <div
                        className={cx(
                            "sticky top-0 grid h-12 grid-cols-[1fr_minmax(min-content,4fr)_1fr] items-center py-0.5 md:grid-cols-[1fr_minmax(min-content,.75fr)_1fr]",
                            props.hideTitleBarOnDesktop && "md:hidden",
                        )}
                    >
                        {props.backButton || props.closeButton ? (
                            <div className={`flex items-center ${props.hideBackButtonOnDesktop ? "md:invisible" : ""}`}>
                                {goBackUrl ? (
                                    <Link href={goBackUrl} legacyBehavior passHref>
                                        <Button isIconButton variant="text" title="Zavřít" size="lg">
                                            <Icon
                                                name={props.backButton ? "arrow-left" : "xmarkLarge"}
                                                size={24}
                                                className="text-lightLow"
                                            />
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button isIconButton onClick={back} variant="text" title="Zpět" size="lg">
                                        <Icon
                                            name={props.backButton ? "arrow-left" : "xmarkLarge"}
                                            size={24}
                                            className="text-lightLow"
                                        />
                                    </Button>
                                )}
                            </div>
                        ) : (
                            <div />
                        )}
                        <p className="uxf-typo-h6 text-center text-lightMedium">{props.title}</p>
                        {props.rightElement ?? <div />}
                    </div>
                )}
            </header>
        </>
    );
};
