import { MenuItemType, USER_MENU_CONFIG } from "@config/menu-config";
import { autoUpdate, flip, offset, shift, size, useFloating } from "@floating-ui/react";
import { Popover } from "@headlessui/react";
import { FormatNumber } from "@localize";
import { useCurrentUser } from "@shared/utils/use-current-user";
import { isMobileWidth } from "@ui/utils/is-mobile-width";
import { useHandleRealHeight } from "@ui/utils/use-handle-real-height";
import { Button } from "@uxf/ui/button";
import { Icon } from "@uxf/ui/icon";
import { TextInput } from "@uxf/ui/text-input";
import Link from "next/link";
import { FC, useRef } from "react";

const MenuItem: FC<MenuItemType> = (item) => {
    const className = "-mx-4 flex items-center p-4";

    const Content = (
        <>
            <Icon name={item.icon} size={24} className="mr-3 text-lightLow" />
            <span className="font-medium text-lightMedium">{item.label}</span>
            <Icon name="chevronRight" size={16} className="ml-auto text-lightBorder" />
        </>
    );

    if (item.enforceBrowserReload) {
        return (
            <a href={item.href} className={className}>
                {Content}
            </a>
        );
    }

    return (
        <Link href={item.href} className={className} prefetch={false}>
            {Content}
        </Link>
    );
};

const Content: FC<{ close: () => void }> = (props) => {
    const user = useCurrentUser();
    const ref = useRef<HTMLDivElement>(null);
    useHandleRealHeight(ref, "height", typeof window !== "undefined" && isMobileWidth(window.innerWidth));

    return (
        <div ref={ref} className="flex h-screen flex-col md:!h-auto md:max-h-[90vh]">
            <div className="grid grid-cols-3 border-b py-3 md:hidden">
                <Button
                    isIconButton
                    variant="text"
                    className="h-auto justify-self-start"
                    onClick={props.close}
                    title="Zavřít"
                >
                    <Icon name="arrow-left" size={18} className="text-lightLow" />
                </Button>
                <p className="uxf-typo-h6 text-center text-lightMedium">Menu</p>
            </div>
            <div className="overflow-auto px-4">
                {user?.isLogged && (
                    <>
                        <div className="mt-6 rounded-xl border border-lightBorder p-4">
                            <p className="uxf-typo-caption font-semibold uppercase text-lightMedium">Váš kredit</p>
                            <p className="uxf-typo-h4 mt-3 text-lightMedium">
                                <FormatNumber value={user.creditBalance} />
                            </p>
                            {!!user.creditBalance && (
                                <p className="uxf-typo-body2 mt-3 text-lightMedium">
                                    Peníze budou automaticky odečteny z&nbsp;vašeho dalšího nákupu.
                                </p>
                            )}
                        </div>
                        <p className="uxf-typo-overline mt-4 uppercase text-lightLow">Můj profil</p>
                    </>
                )}
                {user?.profile && (
                    <TextInput
                        className="my-4"
                        isDisabled
                        isReadOnly
                        label="E-mail"
                        onChange={() => void null}
                        value={user.profile.email}
                    />
                )}
                <div className="divide-y divide-gray-100">
                    {USER_MENU_CONFIG.map((item) => (
                        <MenuItem key={item.label} {...item} />
                    ))}
                </div>
                {/*<div className="md:hidden">
                    <p className="uxf-typo-overline mt-4 uppercase text-lightLow md:hidden">Předplátko</p>
                    <div className="divide-y divide-gray-100">
                        {APP_MENU_CONFIG.map((item) => (
                            <MenuItem key={item.label} {...item} />
                        ))}
                    </div>
                </div>*/}
            </div>
        </div>
    );
};

export const Navigation: FC = () => {
    const float = useFloating({
        placement: "bottom-end",
        middleware: [offset(25), flip(), shift(), size()],
        whileElementsMounted: (reference, floating, update) =>
            autoUpdate(reference, floating, update, {
                elementResize: typeof ResizeObserver !== "undefined",
            }),
    });

    return (
        <Popover className="relative">
            {({ close }) => (
                <>
                    <Popover.Button ref={float.refs.setReference} className="flex items-center">
                        <Icon name="bars" size={24} className="text-lightHigh" />
                    </Popover.Button>
                    <Popover.Panel
                        className="z-menu bg-white max-md:!fixed max-md:!inset-0 max-md:!top-[-74px] md:absolute md:inset-auto md:w-80 md:rounded-2xl md:pb-2 md:shadow-lg"
                        ref={float.refs.setFloating}
                        style={float.floatingStyles}
                    >
                        <Content close={close} />
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};
