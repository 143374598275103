import { CookiesConsentType } from "@uxf/analytics/consent";
import { updateGtmConsent } from "@uxf/analytics/gtm";
import { Button } from "@uxf/ui/button";
import { openModal } from "@uxf/ui/modal/modal-service";
import { memo, useCallback } from "react";
import { CookieConsentFormContainer } from "./form/cookie-consent-modal-form-container";

interface Props {
    onClose: () => void;
}

export const CookieConsentBar = memo<Props>((props) => {
    const setConsentData = useCallback(
        (consentData: CookiesConsentType) => () => {
            updateGtmConsent(consentData);
            props.onClose();
        },
        [props],
    );

    const onAcceptAll = useCallback(() => {
        setConsentData({ analytics_storage: true, personalization_storage: true, ad_storage: true })();
    }, [setConsentData]);

    const onDenyAll = useCallback(() => {
        setConsentData({ analytics_storage: false, personalization_storage: false, ad_storage: false })();
    }, [setConsentData]);

    return (
        <aside className="fixed inset-x-0 bottom-0 z-50 bg-lightHigh p-6 text-lightLow sm:px-10 sm:pt-8">
            <div className="mx-auto max-w-7xl">
                <h2 className="mb-3 text-3xl font-bold text-white">I&nbsp;my používáme cookies</h2>
                <p className="mb-6 xs:mb-8">
                    Díky nim jsou naše stránky bezpečnější a&nbsp;nabízí co možná nejlepší uživatelský zážitek. Cookies
                    nám dále pomáhají pochopit, co Vás na&nbsp;našich stránkách zajímá a&nbsp;co bychom měli vylepšit.
                    Tyto informace můžeme poskytnout i&nbsp;našim obchodním partnerům, aby Vás např. neobtěžovali
                    nerelevantní reklamou.
                </p>
                <div className="grid grid-cols-2 items-center justify-end gap-3 sm:flex sm:gap-0 sm:space-x-4">
                    <Button onClick={onDenyAll} variant="white" className="xs:px-8">
                        Nesouhlasím
                    </Button>
                    <Button
                        onClick={() => {
                            openModal({
                                children: <CookieConsentFormContainer setConsentData={setConsentData} />,
                                width: "default",
                            });
                        }}
                        variant="white"
                        className="xs:px-8"
                    >
                        Preference
                    </Button>
                    <Button
                        className="col-span-2 flex justify-center xs:px-8 sm:col-span-1"
                        onClick={onAcceptAll}
                        variant="default"
                    >
                        Souhlasím
                    </Button>
                </div>
            </div>
        </aside>
    );
});

CookieConsentBar.displayName = "CookieConsentBar";
