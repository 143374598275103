import { isConsentCookieSet } from "@uxf/analytics/consent";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import { useIsomorphicLayoutEffect } from "@uxf/core/hooks/useIsomorphicLayoutEffect";
import { FC, memo, useCallback, useState } from "react";
import { CookieConsentBar } from "./cookie-consent-bar";

const Component: FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = useCallback(() => setIsOpen(false), []);

    useIsomorphicLayoutEffect(() => {
        const isConsentSet = isConsentCookieSet();
        if (!isConsentSet) {
            setIsOpen(true);
        }
    }, []);

    return <>{isOpen && <CookieConsentBar onClose={closeModal} />}</>;
};

export const CookieConsent = memo((props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component {...props} /> : null;
});

CookieConsent.displayName = "CookieConsent";
