import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import dynamic from "next/dynamic";
import { FC, memo } from "react";
import type { CookieConsentModalFormProps } from "./cookie-consent-modal-form";

const CookieConsentModalForm = dynamic<CookieConsentModalFormProps>(
    () => import("./cookie-consent-modal-form").then((component) => component.CookieConsentModalForm),
    {
        ssr: false,
    },
);

const Component: FC<CookieConsentModalFormProps> = (props) => {
    return <CookieConsentModalForm setConsentData={props.setConsentData} />;
};

export const CookieConsentFormContainer = memo<CookieConsentModalFormProps>((props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component setConsentData={props.setConsentData} /> : null;
});

CookieConsentFormContainer.displayName = "CookieConsentFormContainer";
